<template>
  <div class="login">
    <div class="form">
      <h1 class="title">盘工-生产管理可视平台</h1>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" size="medium">
        <el-form-item label prop="username">
          <el-input v-model.trim="ruleForm.username" autocomplete="off" placeholder="请输入账号" @keyup.enter.native="submitForm('ruleForm')">
            <i slot="prefix" class="el-input__icon el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item label prop="password">
          <el-input type="password" v-model.trim="ruleForm.password" autocomplete="off" placeholder="请输入密码" @keyup.enter.native="submitForm('ruleForm')">
            <i slot="prefix" class="el-input__icon el-icon-lock"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit" type="primary" @click="submitForm('ruleForm')" :loading="loading">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="footerLogin">
      盘工-生产管理平台 - <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">闽ICP备18016756号-1 </a>
    </div>
  </div>
</template>
<script>
import { login } from "@/service/user";
import { setToken, getToken, setName, setLogo, setcompany } from "@/service/lib/localToken";
import Cookies from 'js-cookie';
export default {
  name: "Login",
  data () {
    return {
      loading: false,
      ruleForm: {
        username: "",
        password: ""
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 3, max: 32, message: "长度在 3 到 32 个字符", trigger: "blur" }
        ]
      },
      redirect: undefined,
      otherQuery: {}
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  methods: {
    submitForm (formName) {
      const _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          _this.loading = true;
          const paramData = {
            username: _this.ruleForm.username,
            password: _this.ruleForm.password
          };
          login(paramData)
            .then(res => {
              if (res.code === 200) {
                const { token, user } = res.data;
                setToken(token);
                Cookies.set('companyId', user.company_id);
                Cookies.set('userId', user.id);
                setName(res.data.user.company.name);
                setLogo(res.data.user.company.logo);
                setcompany(res.data.user.company);
                console.log(getToken());
                _this.loading = false;

                _this.$router.push({ path: _this.redirect || '/statistics', query: _this.otherQuery }, () => { });
              }
            })
            .catch(error => {
              _this.$message.error(error.message);
              _this.loading = false;
            });
          // axios.post('/user', {
          //     firstName: 'Fred',
          //     lastName: 'Flintstone'
          // }).then(function (response) {
          //     console.log(response);
          // }).catch(function (error) {
          //     console.log(error);
          // });
          // _this.$store.dispatch('user/login', paramData).then(() => {
          //     _this.loading = false;
          //     _this.$router.push({ path: _this.redirect || '/', query: _this.otherQuery },() => {});
          //   }).catch(error => {
          //     _this.$message.error(error.message);
          //     _this.loading = false;
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getOtherQuery (query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  overflow: auto;
  background-image: url('../../assets/images/bg-smooth.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;
  .form {
    flex: none;
    width: 320px;
    padding: 60px;
    margin: 0 auto;
    border-radius: 4px;
    background-color: rgba($color: #ffffff, $alpha: 0.2);
    .title {
      font-weight: 300;
      margin-top: 0;
      margin-bottom: 60px;
      text-align: center;
      color: #ffffff;
    }
    .submit {
      margin-top: 30px;
      width: 100%;
    }
  }
}
.footerLogin {
  position: fixed;
  bottom: 50px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  color: #808695;
  a {
    color: #808695;
  }
}
@media screen and (max-width: 750px) {
  .login {
    display: flex;
    width: 100%;
    height: 100vh;
    min-height: 500px;
    overflow: auto;
    background-image: url('../../assets/images/bg-smooth.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    background-size: cover;
    align-items: center;
    .form {
      flex: none;
      width: 80%;
      box-sizing: content-box;
      padding: 10px;
      margin: 0 auto;
      border-radius: 4px;
      background-color: rgba($color: #ffffff, $alpha: 0.2);
      .title {
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 30px;
        text-align: center;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
      }
      .submit {
        margin-top: 10px;
        width: 100%;
      }
    }
  }
}
</style>
